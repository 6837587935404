export const uxui = {
    "moya": {
        "color": "#01A558",
        "overview": {
            "images": ["/images/projects/moya/cover-1.webp", "/images/projects/moya/cover-2.webp", "/images/projects/moya/cover-3.webp"]
        },
        "problem": {
            "images": ["/images/projects/moya/problem-analyisis.webp", "/images/projects/moya/workplace-employee-ratio.webp", "/images/projects/moya/insights.webp", "/images/projects/moya/persona.webp", "/images/projects/moya/empathy.webp"]
        },
        "goal": {
            "images": ["/images/projects/moya/system-architecture.webp", "/images/projects/moya/matrix.webp", "/images/projects/moya/features.webp", "/images/projects/moya/scrum.webp"]
        },
        "ideation": {
            "images": ["/images/projects/moya/ia.webp", "/images/projects/moya/workflow.webp", "/images/projects/moya/userflow-operator.webp", "/images/projects/moya/validate-container.webp", "/images/projects/moya/upload-data.webp"]
        },
        "design": {
            "images": ["/images/projects/moya/design-1.webp", "/images/projects/moya/design-2.webp", "/images/projects/moya/design-3.webp", "/images/projects/moya/design-4.webp", "/images/projects/moya/design-5.webp", "/images/projects/moya/design-6.webp", "/images/projects/moya/design-7.webp", "/images/projects/moya/design-8.webp", "/images/projects/moya/design-9.webp", "/images/projects/moya/design-10.webp"]
        },
        "handoff": {
            "images": ["/images/projects/moya/color-light.webp", "/images/projects/moya/color-dark.webp", "/images/projects/moya/design-system-light.webp", "/images/projects/moya/design-system-dark.webp"]
        },
        "impact": {
            "images": ["/images/projects/moya/impact-1.webp", "/images/projects/moya/impact-2.webp", "/images/projects/moya/impact-3.webp"]
        }
    },
    "sevenpeaks": {
        "color": "#146FE2",
        "overview": {
            "images": ["/images/projects/sevenpeaks/cover-1.webp", "/images/projects/sevenpeaks/cover-2.webp", "/images/projects/sevenpeaks/cover-3.webp"]
        },
        "problem": {
            "images": ["/images/projects/sevenpeaks/system-analysis.webp", "/images/projects/sevenpeaks/pain-points.webp", "/images/projects/sevenpeaks/empathy.webp"]
        },
        "goal": {
            "images": ["/images/projects/sevenpeaks/implementation-goal.webp", "/images/projects/sevenpeaks/matrix.webp", "/images/projects/sevenpeaks/hubspot-features.webp", "/images/projects/sevenpeaks/work-methodology.webp"]
        },
        "ideation": {
            "images": ["/images/projects/sevenpeaks/workflow.webp", "/images/projects/sevenpeaks/ia.webp", "/images/projects/sevenpeaks/hubspot-integration.webp"]
        },
        "design": {
            "images": ["/images/projects/sevenpeaks/design-1.webp", "/images/projects/sevenpeaks/design-2.webp", "/images/projects/sevenpeaks/design-3.webp", "/images/projects/sevenpeaks/design-4.webp", "/images/projects/sevenpeaks/design-5.webp"]
        },
        "handoff": {
            "images": ["/images/projects/sevenpeaks/system.webp", "/images/projects/sevenpeaks/color-palette-1.webp", "/images/projects/sevenpeaks/color-palette-2.webp"]
        },
        "impact": {
            "images": ["/images/projects/sevenpeaks/analysis-impact.webp", "/images/projects/sevenpeaks/impact-report.webp", "/images/projects/sevenpeaks/back-cover.webp"]
        }
    },
    "arcadia": {
        "color": "#6C2BD9",
        "overview": {
            "images": ["/images/projects/arcadia/cover-1.webp", "/images/projects/arcadia/cover-2.webp"]
        },
        "problem": {
            "images": []
        },
        "goal": {
            "images": []
        },
        "ideation": {
            "images": []
        },
        "design": {
            "images": []
        },
        "handoff": {
            "images": []
        },
        "impact": {
            "images": []
        }
    },
    "alkewallet": {
        "color": "#8767b0",
        "overview": {
            "images": []
        },
        "problem": {
            "images": []
        },
        "goal": {
            "images": []
        },
        "ideation": {
            "images": []
        },
        "design": {
            "images": []
        },
        "handoff": {
            "images": []
        },
        "impact": {
            "images": []
        }
    },
    "eyed": {
        "color": "#8FBE21",
        "overview": {
            "images": []
        },
        "problem": {
            "images": []
        },
        "goal": {
            "images": []
        },
        "ideation": {
            "images": []
        },
        "design": {
            "images": []
        },
        "handoff": {
            "images": []
        },
        "impact": {
            "images": []
        }
    },
    "lumamate": {
        "color": "#66B924",
        "overview": {
            "images": ["/images/projects/lumamate/cover-1.webp", "/images/projects/lumamate/cover-2.webp"]
        },
        "problem": {
            "images": ["/images/projects/lumamate/user-persona.webp"]
        },
        "goal": {
            "images": []
        },
        "ideation": {
            "images": []
        },
        "design": {
            "images": []
        },
        "handoff": {
            "images": ["/images/projects/lumamate/color-palette.webp", "/images/projects/lumamate/color-palette-2.webp"]
        },
        "impact": {
            "images": ["/images/projects/lumamate/impact-1.webp", "/images/projects/lumamate/back-cover.webp"]
        }
    },
    "dmeal": {
        "color": "#E38203",
        "overview": {
            "images": []
        },
        "problem": {
            "images": []
        },
        "goal": {
            "images": []
        },
        "ideation": {
            "images": []
        },
        "design": {
            "images": []
        },
        "handoff": {
            "images": []
        },
        "impact": {
            "images": []
        }
    },
    "kuadi": {
        "color": "#F9C016",
        "overview": {
            "images": ["/images/projects/kuadi/cover-1.webp"]
        },
        "problem": {
            "images": []
        },
        "goal": {
            "images": []
        },
        "ideation": {
            "images": []
        },
        "design": {
            "images": []
        },
        "handoff": {
            "images": []
        },
        "impact": {
            "images": []
        }
    },
    "tikcoin": {
        "color": "#1A8F85",
        "overview": {
            "images": []
        },
        "problem": {
            "images": []
        },
        "goal": {
            "images": []
        },
        "ideation": {
            "images": []
        },
        "design": {
            "images": []
        },
        "handoff": {
            "images": []
        },
        "impact": {
            "images": []
        }
    }
}

export const dev = {
    "pokequest": {
        "color": "#01A558",
        "overview": {
            "images": []
        },
        "features": {
            "images": []
        },
        "technologies": {
            "images": []
        },
        "hosting": {
            "images": []
        }
    },
    "zentask": {
        "color": "#01A558",
        "overview": {
            "images": []
        },
        "features": {
            "images": []
        },
        "technologies": {
            "images": []
        },
        "hosting": {
            "images": []
        }
    },
    "pokefinder": {
        "color": "#01A558",
        "overview": {
            "images": []
        },
        "features": {
            "images": []
        },
        "technologies": {
            "images": []
        },
        "hosting": {
            "images": []
        }
    },
    "mathly": {
        "color": "#01A558",
        "overview": {
            "images": []
        },
        "features": {
            "images": []
        },
        "technologies": {
            "images": []
        },
        "hosting": {
            "images": []
        }
    },
    "splitwise": {
        "color": "#01A558",
        "overview": {
            "images": []
        },
        "features": {
            "images": []
        },
        "technologies": {
            "images": []
        },
        "hosting": {
            "images": []
        }
    },
    "epichero": {
        "color": "#01A558",
        "overview": {
            "images": []
        },
        "features": {
            "images": []
        },
        "technologies": {
            "images": []
        },
        "hosting": {
            "images": []
        }
    },
    "zootune": {
        "color": "#01A558",
        "overview": {
            "images": []
        },
        "features": {
            "images": []
        },
        "technologies": {
            "images": []
        },
        "hosting": {
            "images": []
        }
    }
}

export const product = {
    "apexride": {
        "color": "#E52E2F",
        "overview": {
            "images": ["/images/projects/apexride/cover-1.webp", "/images/projects/apexride/cover-2.webp"]
        },
        "problem": {
            "images": []
        },
        "goal": {
            "images": []
        },
        "ideation": {
            "images": []
        },
        "design": {
            "images": []
        },
        "impact": {
            "images": []
        }
    },
    "solaris": {
        "color": "#6FA557",
        "overview": {
            "images": ["/images/projects/solaris/cover-1.webp", "/images/projects/solaris/cover-2.webp"]
        },
        "problem": {
            "images": []
        },
        "goal": {
            "images": []
        },
        "ideation": {
            "images": []
        },
        "design": {
            "images": []
        },
        "impact": {
            "images": []
        }
    },
    "ciel": {
        "color": "#55B5D8",
        "overview": {
            "images": ["/images/projects/ciel/cover-1.webp"]
        },
        "problem": {
            "images": []
        },
        "goal": {
            "images": []
        },
        "ideation": {
            "images": []
        },
        "design": {
            "images": []
        },
        "impact": {
            "images": []
        }
    },
}

export const branding = {
    "melhuish": {
        "color": "#0787C5",
        "overview": {
            "images": []
        },
        "problem": {
            "images": []
        },
        "goal": {
            "images": []
        },
        "ideation": {
            "images": []
        },
        "design": {
            "images": []
        },
        "impact": {
            "images": []
        }
    },
    "americanscrew": {
        "color": "#002364",
        "overview": {
            "images": []
        },
        "problem": {
            "images": []
        },
        "goal": {
            "images": []
        },
        "ideation": {
            "images": []
        },
        "design": {
            "images": []
        },
        "impact": {
            "images": []
        }
    },
    "byvingenieria": {
        "color": "#EF7B0B",
        "overview": {
            "images": []
        },
        "problem": {
            "images": []
        },
        "goal": {
            "images": []
        },
        "ideation": {
            "images": []
        },
        "design": {
            "images": []
        },
        "impact": {
            "images": []
        }
    },
    "mundoguided": {
        "color": "#2D73B7",
        "overview": {
            "images": []
        },
        "problem": {
            "images": []
        },
        "goal": {
            "images": []
        },
        "ideation": {
            "images": []
        },
        "design": {
            "images": []
        },
        "impact": {
            "images": []
        }
    },
    "ballenasubmarina": {
        "color": "#1FA6B6",
        "overview": {
            "images": []
        },
        "problem": {
            "images": []
        },
        "goal": {
            "images": []
        },
        "ideation": {
            "images": []
        },
        "design": {
            "images": []
        },
        "impact": {
            "images": []
        }
    },
    "fishworld": {
        "color": "#2DB45D",
        "overview": {
            "images": []
        },
        "problem": {
            "images": []
        },
        "goal": {
            "images": []
        },
        "ideation": {
            "images": []
        },
        "design": {
            "images": []
        },
        "impact": {
            "images": []
        }
    },
}

export const other = {
    "dev1": {
        "color": "#01A558",
        "overview": {
            "images": []
        },
        "features": {
            "images": []
        },
        "technologies": {
            "images": []
        },
        "hosting": {
            "images": []
        }
    }
}
